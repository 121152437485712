import {campaignApi} from "../../api/api-wrapper";
import {CampaignDto} from "../../api/generated";
import {autobind, computed} from "knockout-decorators";
import {Context, Router} from "@profiscience/knockout-contrib-router";
import * as ko from "knockout";
import '../../components/elements/campaigns/campaign-list-item';
import '../../components/elements/campaigns/active-campaign-item';
import {hasEnded, isActive, isFuture} from "./campaignUtils";
import globalState from '../../global-state';

interface CampaignsListViewModelContext extends Context {
    campaigns: CampaignDto[];
}

class CampaignsListViewModel {

    public campaigns: CampaignDto[];

    /**
     * The current page number for the pagination.
     */
    public currentPage: KnockoutObservable<number>;

    /**
     * The number of items per page.
     */
    public pageSize: KnockoutObservable<number>;

    constructor(ctx: CampaignsListViewModelContext) {
        // show invisible news to admin
        this.campaigns = ctx.campaigns;
        this.currentPage = ko.observable(1);
        this.pageSize = ko.observable(globalState.appVars.campaignsPageSize || 6);
    }

    public get endedCampaigns() {
        return this.campaigns
            .filter(campaign => hasEnded(campaign));
    }

    public get futureCampaigns() {
        return this.campaigns.filter(campaign => isFuture(campaign));
    }

    public get activeCampaigns(): CampaignDto[] {
        return this.campaigns.filter(campaign => this.isActive(campaign));
    }

    private isActive(campaign: CampaignDto) {
        return isActive(campaign);
    }

    @computed
    public get endedCampaignsPaged(): CampaignDto[] {
        const pageStart = (this.currentPage() - 1) * this.pageSize();
        const pageEnd = pageStart + this.pageSize();

        return this.endedCampaigns.slice(pageStart, pageEnd);
    }

    @autobind
    public addCampaign() {
        return Router.update('/kampagne/neu');
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: CampaignsListViewModel,
    template: require('./campaignList.html'),
    componentName: "campaign-list",
    loader: (ctx: CampaignsListViewModelContext) => {
        document.title = `${document.title} - Kampagnen`;
        return campaignApi.getCampaigns()
            .then(campaigns => ctx.campaigns = campaigns);
    }
};
