import {EvaluationReportsViewModel, EvaluationReportsViewModelContext} from "./BaseViewModel";
import {evaluationApi} from "../../../api/api-wrapper";
import * as ko from "knockout";
import {EvaluationCriteria, EvaluationCriteriaDto, EvaluationDto} from "../../../api/generated";
import {autobind} from "knockout-decorators";
import {averageScore} from "../../evaluation/evaluationUtils";
import "knockout.chart";
import moment = require("moment");

class Quality {
    date: Date;
    year: number;
    month: number;
    monthName: string;
    evaluationsCnt: number;
    scoreTotal: number;

    constructor(date: moment.Moment) {
        this.date = date.toDate();
        this.year = date.year();
        this.month = date.month();
        this.monthName = date.format('MMMM')
        this.evaluationsCnt = 0;
        this.scoreTotal = 0;
    }

    public averageScore() {
        return averageScore(this.scoreTotal, this.evaluationsCnt);
    }

    public addScore(score: number) {
        this.scoreTotal = this.scoreTotal + score;
        this.evaluationsCnt++;
    }
}

class ViewModel extends EvaluationReportsViewModel {

    /**
     * The criterias ContentQuality and FormalQuality should be considered for the quality only.
     */
    private qualityCriteriaTypes: EvaluationCriteria.TypeEnum[] = [
        EvaluationCriteria.TypeEnum.FormalQuality,
        EvaluationCriteria.TypeEnum.ContentQuality
    ];

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: EvaluationReportsViewModelContext) {
        super(ctx);
        // Evaluations with quality criterias only.
        this.evaluations = this.evaluations.filter(evaluation => evaluation.submitted && evaluation.active
            && this.hasQualityCriterias(evaluation));

        // Default start date is one year ago
        // Do not read / store these filters in global state
        this.dateFromFilter = ko.observable(moment().subtract(1, 'years').startOf('month').toDate());
        this.dateToFilter = ko.observable(null);
    }

    /**
     * Check if an evaluation has quality criterias.
     * @param evaluation
     */
    private hasQualityCriterias(evaluation: EvaluationDto) {
        // TODO sollen nur Evaluierungen berücksichtigt werden, die alle Kriterien beihalten?  === this.qualityCriteriaTypes.length
        return this.getCriteriasByType(evaluation, this.qualityCriteriaTypes).length > 0;
    }

    /**
     * Get the quality criterias for an evaluation.
     *
     * @param evaluation
     * @param criteriaTypes
     */
    private getCriteriasByType(evaluation: EvaluationDto, criteriaTypes: EvaluationCriteria.TypeEnum[]): EvaluationCriteriaDto[] {
        return evaluation.criteriaList ? evaluation.criteriaList.filter(criteria =>
            criteriaTypes.find(type => type === criteria.type) !== undefined
        ) : [];
    }

    /**
     * Get the evaluations filtered and sorted.
     * The date filter checks the idea date!!
     */
    @autobind
    public evaluationsFiltered(): KnockoutComputed<EvaluationDto[]> {
        return ko.pureComputed(() => {
            return this.evaluations.filter(evaluation => {
                if (this.dateFromFilter() !== undefined
                    && moment(evaluation.idea.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(evaluation.idea.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                return true;
            }).sort((eval1, eval2) =>
                (<string><any>eval1.idea.created).localeCompare(<string><any>eval2.idea.created) * -1
            );
        });
    }

    /**
     * Quality of the ideas per month.
     */
    public ideasQuality(): KnockoutComputed<Quality[]> {
        return ko.pureComputed(() => {

            let monthsMap = new Map<string, Quality>();
            this.evaluationsFiltered()().forEach(evaluation => {
                const ideaCreated: moment.Moment = moment(evaluation.idea.created);
                const key: string = ideaCreated.year() + '' + ideaCreated.month();
                if (!monthsMap.has(key)) {
                    monthsMap.set(key, new Quality(ideaCreated));
                }
                monthsMap.get(key).addScore(this.averageScore(evaluation, this.qualityCriteriaTypes));
            });

            return Array.from(monthsMap.values());
        });

    }

    /**
     * Data for the chart.
     */
    public chartData(): KnockoutComputed<any> {
        return ko.pureComputed(() => {
            let qualityList: Quality[] = this.ideasQuality()();
            return {
                labels: qualityList.map(quality => [quality.monthName, quality.year]),
                datasets: [{
                    label: "Qualität der Ideen",
                    backgroundColor: "rgba(0, 122, 155, 0.7)",
                    borderColor: "rgba(0, 122, 155, 1)",
                    pointColor: "rgba(0, 122, 155, 1)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(0, 122, 155, 1)",
                    data: qualityList.map(quality => quality.averageScore())
                }]
            };
        });
    }

    /**
     * Sets the chart height according to the amount of rows.
     */
    public chartHeight(): KnockoutComputed<string> {
        return ko.pureComputed(() => {
            return Math.round(this.ideasQuality()().length * 40 + 100) + 'px';
        });
    }

    /**
     * Average quality score for a evaluation for the given types.
     * If criteriaTypes param is null, all types are considered.
     * @param evaluation
     *
     */
    private averageScore(evaluation: EvaluationDto, criteriaTypes: EvaluationCriteria.TypeEnum[]): number {
        const criteriasForTypes: EvaluationCriteriaDto[] = criteriaTypes ?
            this.getCriteriasByType(evaluation, this.qualityCriteriaTypes) :
            evaluation.criteriaList;

        const scoreTotal: number = criteriasForTypes.reduce((previousValue, criteria) =>
            previousValue + criteria.score, 0);
        return averageScore(scoreTotal, criteriasForTypes.length);
    }
}


export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./ideasQuality.html'),
    componentName: "ideasQuality",
    loader: (ctx: EvaluationReportsViewModelContext) => {
        document.title = `${document.title} - Report Ideen Qualität`;
        return Promise.all([
            evaluationApi.getEvaluations().then(evaluations => {
                ctx.evaluations = evaluations;
            })
        ]);
    }
};
