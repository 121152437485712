import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import * as moment from "moment";
import {BaseReportsViewModel} from "./BaseViewModel";
import {IdeaDto} from "../../../api/generated";
import {ideaStateOptions, isFilterActive} from "../../ideas/ideaUtils";
import {ideaApi} from "../../../api/api-wrapper";
import {departmentName} from "../../../components/elements/user/userUtil";

class ViewModelContext extends Context {
    ideas: IdeaDto[];
}

interface Departments {
    name: string;
    ideas: number;
    percentage: number;
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The ideas.
     */
    public ideas: IdeaDto[];

    /**
     * The selected idea type.
     */
    public ideaTypeFilter: KnockoutObservable<string>;

    /**
     * The selected idea states.
     */
    public ideaStateFilter: KnockoutObservableArray<string>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.ideas = ctx.ideas;
        this.ideaTypeFilter = ko.observable("");
        this.ideaStateFilter = ko.observableArray([]);
    }

    /**
     * Get the ideas filtered and sorted.
     */
    @autobind
    public ideasFiltered(): KnockoutComputed<IdeaDto[]> {
        return ko.pureComputed(() => {
            return this.ideas.filter(idea => {
                if (this.dateFromFilter() !== undefined
                    && moment(idea.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(idea.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                if (isFilterActive(this.ideaTypeFilter) && idea.type && idea.type.toString() !== this.ideaTypeFilter()) {
                    return false;
                }
                if (this.ideaStateFilter() && this.ideaStateFilter().length > 0
                    && idea.state && !this.ideaStateFilter().includes(idea.state.toString())) {
                    return false;
                }
                return true;
            })
        });
    }


    @autobind
    public departments(): KnockoutComputed<Departments[]> {
        return ko.pureComputed(() => {
            let departments: Departments[] = [];
            let ideasTotal = this.ideasFiltered()().length;

            let departmentMap = new Map();
            this.ideasFiltered()().forEach(idea => {
                let department = idea.user.department ? idea.user.department : "Unbekannt";
                if (departmentMap.has(department)) {
                    departmentMap.set(department, departmentMap.get(department) + 1);
                } else {
                    departmentMap.set(department, 1)
                }
            });

            for (let [key, value] of departmentMap) {
                departments.push({
                    name: departmentName(key),
                    ideas: value,
                    percentage: this.percentage(ideasTotal, value)
                })
            }

            return departments;
        });
    }

    /**
     * Get the options for the idea type filter.
     * State submission is not visible.
     */
    public ideaStateOptions() {
        return ideaStateOptions()
            .filter(option => option.value != 'submission');
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./ideasPerDepartment.html'),
    componentName: "ideasPerDepartment",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Ideen nach Abteilung`;
        return Promise.all([
            ideaApi.getIdeas().then(ideas => {
                ctx.ideas = ideas;
            })
        ]);
    }
};
