import * as ko from "knockout";
import {CampaignDto, WidgetDto} from "../../api/generated";
import {autobind, observable} from "knockout-decorators";
import '../../components/elements/news/sidebar-news-item';
import {campaignApi} from "../../api/api-wrapper";
import {Context, Router} from "@profiscience/knockout-contrib-router";
import globalState from "../../global-state";
import {postbox} from "../../components/util/postbox";
import {createConfirmModal} from "../../components/elements/modal/modal";
import i18nextko from "../../bindings/i18nko";
import '../editor/widget-utils';
import * as common from "../editor/_common";
import "../../components/elements/countdown/countdown";
import {setInterval} from "timers";
import {config} from "../../utils/clientConfigWrapper";
import moment = require("moment");

export class CampaignViewModelContext extends Context {
    campaign: CampaignDto;
    params: {
        id: number;
    };
}

class ViewModel {

    public basePath: string = `${config.attachmentEndpoint}${config.campaignsUploadPath}/`;

    public campaign: CampaignDto;

    public now: KnockoutObservable<Date>;
    public daysLeft: KnockoutComputed<number>;
    public hoursLeft: KnockoutComputed<number>;
    public minutesLeft: KnockoutComputed<number>;

    @observable({deep: true})
    public widget: WidgetDto;

    constructor(ctx: CampaignViewModelContext) {
        if (ctx.campaign) {
            this.campaign = ctx.campaign;
            if (ctx.campaign.widget) {
                this.widget = ctx.campaign.widget;
            } else {
                this.widget = {
                    id: null,
                    type: WidgetDto.TypeEnum.CAMPAIGN,
                    content: {
                        headline: <any>this.campaign.title,
                        description: <any>this.campaign.description,
                        start: <any>this.campaign.start,
                        end: <any>this.campaign.end,
                        teaserImageRef: <any>this.campaign.image,
                        imageRef: <any>this.campaign.sliderImage
                    },
                    children: []
                };
            }

        } else {
            this.campaign = {
                id: null,
                ideaList: []
            };
            this.widget = {
                id: null,
                type: WidgetDto.TypeEnum.CAMPAIGN,
                content: {
                    imageRef: null
                },
                children: []
            };
        }

        this.now = ko.observable(new Date());
        this.daysLeft = ko.pureComputed(() =>
            moment(this.campaign.end).endOf('day').diff(this.now(), 'days')
        );
        this.hoursLeft = ko.pureComputed(() =>
            moment().endOf('day').diff(this.now(), 'hours')
        );
        this.minutesLeft = ko.pureComputed(() =>
            moment().endOf('hour').diff(this.now(), 'minutes')
        );
        setInterval(() => {
                this.now(new Date())
            }, 60000
        );
    }

    public get daysTotal() {
        return moment(this.campaign.end).endOf('day')
            .diff(moment(this.campaign.start).startOf('day'), 'days')
    }

    public get isActive() {
        return this.campaign.start && this.campaign.end &&
            moment().startOf('day').isBetween(moment(this.campaign.start), moment(this.campaign.end),
                'day', '[]');
    }

    public get ideasCount(): number {
        return this.campaign.ideaList.length;
    }

    public get ideas() {
        return this.campaign.ideaList.sort((idea1, idea2) => {
            return moment(idea2.created).diff(moment(idea1.created));
        }) || [];
    }

    @autobind
    public saveCampaign() {
        globalState.loading(true);

        const widgetContent = <common.WidgetContentCampaign>this.widget.content;
        this.campaign.title = widgetContent.headline;
        this.campaign.description = widgetContent.description;
        this.campaign.start = widgetContent.start && moment(widgetContent.start).format('YYYY-MM-DD');
        this.campaign.end = widgetContent.end && moment(widgetContent.end).format('YYYY-MM-DD');
        this.campaign.image = widgetContent.teaserImageRef;
        this.campaign.sliderImage = widgetContent.imageRef;
        this.campaign.widget = this.widget;

        const savePromise = this.campaign.id ?
            campaignApi.putCampaign(this.campaign.id, this.campaign) :
            campaignApi.postCampaign(this.campaign);
        return savePromise
            .then(savedCampaign => {
                postbox.addSuccess('editor.success.submit');

                // update campaign to prevent duplicating campaigns on newly created campaigns
                this.campaign.id = savedCampaign.id;
                this.campaign.widget.id = savedCampaign.widget.id;

                return savedCampaign;
            })
            .catch(err => {
                postbox.addError('editor.error.submit');

                return Promise.reject(err);
            })
            .finally(() => globalState.loading(false));
    }

    @autobind
    public deleteCampaign() {
        return createConfirmModal(
            i18nextko.t("editor.modal.delete.body"),
            i18nextko.t("editor.modal.delete.header"),
            i18nextko.t("global.delete"),
            i18nextko.t("global.cancel"))
            .then(() => {
                globalState.loading(true);

                return campaignApi.deleteCampaign(this.campaign.id).then(
                    () => {
                        postbox.addSuccess('editor.success.delete');

                        return Router.update('kampagne');
                    })
                    .catch(err => {
                        postbox.addError('editor.error.deleteCampaign');

                        return Promise.reject(err);
                    })
                    .finally(() => globalState.loading(false));
            });
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./campaign.html'),
    componentName: "campaign",
    loader: (ctx: CampaignViewModelContext) => {
        document.title = `${document.title} - Kampagne ${ctx.params && ctx.params.id || ''}`;
        return ctx.params && ctx.params.id ?
            campaignApi.getCampaign(ctx.params.id)
                .then(campaign => ctx.campaign = campaign) :
            Promise.resolve(null);
    }
};
