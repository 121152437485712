import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import * as moment from "moment";
import {BaseReportsViewModel} from "./BaseViewModel";
import {IdeaDto} from "../../../api/generated";
import {isFilterActive} from "../../ideas/ideaUtils";
import {ideaApi} from "../../../api/api-wrapper";

class ViewModelContext extends Context {
    ideas: IdeaDto[];
}

interface Categories {
    name: string;
    ideas: number;
    percentage: number;
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The ideas.
     */
    public ideas: IdeaDto[];

    /**
     * The selected idea type.
     */
    public ideaTypeFilter: KnockoutObservable<string>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.ideas = ctx.ideas;
        this.ideaTypeFilter = ko.observable("");
    }

    /**
     * Get the ideas filtered and sorted.
     */
    @autobind
    public ideasFiltered(): KnockoutComputed<IdeaDto[]> {
        return ko.pureComputed(() => {
            return this.ideas.filter(idea => {
                if (this.dateFromFilter() !== undefined
                    && moment(idea.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(idea.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                if (isFilterActive(this.ideaTypeFilter) && idea.type && idea.type.toString() !== this.ideaTypeFilter()) {
                    return false;
                }
                return true;
            })
        });
    }

    @autobind
    public categories(): KnockoutComputed<Categories[]> {
        return ko.pureComputed(() => {
            let categories: Categories[] = [];
            let ideasTotal = this.ideasFiltered()().length;

            let categoryMap = new Map();
            this.ideasFiltered()().forEach(idea => {
                let category = idea.category ? idea.category.name : "Unbekannt";
                if (categoryMap.has(category)) {
                    categoryMap.set(category, categoryMap.get(category) + 1);
                } else {
                    categoryMap.set(category, 1)
                }
            });

            for (let [key, value] of categoryMap) {
                categories.push({
                    name: key,
                    ideas: value,
                    percentage: this.percentage(ideasTotal, value)
                })
            }

            return categories;
        });
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./ideasPerCategory.html'),
    componentName: "ideasPerCategory",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Ideen nach Kategorie`;
        return Promise.all([
            ideaApi.getIdeas().then(ideas => {
                ctx.ideas = ideas;
            })
        ]);
    }
};
