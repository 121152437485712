import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import {BaseReportsViewModel} from "./BaseViewModel";
import {UserDto} from "../../../api/generated";
import {userApi} from "../../../api/api-wrapper";
import {config} from "../../../utils/clientConfigWrapper";
import {dataTableOptions} from "./reportUtils";

class ViewModelContext extends Context {
    users: UserDto[];
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The ideas.
     */
    public users: UserDto[];

    /**
     * Flag whether Admin should be included.
     */
    public includeAdmins: KnockoutObservable<boolean>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.users = ctx.users;
        this.includeAdmins = ko.observable(true);
    }

    /**
     * Get the ideas filtered and sorted.
     */
    @autobind
    public usersFiltered(): KnockoutComputed<UserDto[]> {
        return ko.pureComputed(() =>
            this.users.filter(user =>
                !(!this.includeAdmins() && user.admin)));
    }

    public imageStyle(user: UserDto): string {
        if (user.image && user.image.length > 0) {
            const imageUrl = user.image.startsWith("http") ? user.image :
                `${config.attachmentEndpoint}${user.image}`;
            return `background-image: url('${imageUrl}'), url('/${config.publicPath}/images/dummy.png');`;

        } else {
            return `background-image: url('/${config.publicPath}/images/dummy.png');`
        }
    }

    public award(awardLevel: string): string {
        switch (awardLevel.toString()) {
            case 'bronze':
                return '🥉';
            case 'silver':
                return '🥈';
            case 'gold':
                return '🥇';
            default:
                return '➖';
        }
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./usersOverview.html'),
    componentName: "usersOverview",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report User Überblick`;
        return Promise.all([
            userApi
                .getUsers(null, null, 'full')
                .then(users => ctx.users = users)
        ]);
    }
};
