import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import {BaseReportsViewModel} from "./BaseViewModel";
import {UserDto} from "../../../api/generated";
import {userApi} from "../../../api/api-wrapper";
import globalState from "../../../global-state";

class ViewModelContext extends Context {
    users: UserDto[];
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The users.
     */
    public users: UserDto[];

    /**
     * Flag whether Admin should be included.
     */
    public includeAdmins: KnockoutObservable<boolean>;

    /**
     * Default user award thresholds.
     * @private
     */
    private userAwardThresholdsDefault: { [key: string]: Array<number> }

    /**
     * The Award Levels
     * @private
     */
    private userAwardLevels: string[];

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.users = ctx.users.filter(user =>
            (user.codeveloperAward && user.codeveloperAward > 0) ||
            (user.commentsAward && user.commentsAward > 0) ||
            (user.evaluatorAward && user.evaluatorAward > 0) ||
            (user.ideasImplementedAward && user.ideasImplementedAward > 0) ||
            (user.ideasSubmittedAward && user.ideasSubmittedAward > 0) ||
            (user.perfectIdeasAward && user.perfectIdeasAward > 0)
        );
        this.includeAdmins = ko.observable(false);

        this.userAwardThresholdsDefault = {
            "comments": [20, 70, 150],
            "ideasSubmitted": [3, 10, 25],
            "codeveloper": [1, 5, 10],
            "perfectIdeas": [3, 10, 30],
            "ideasImplemented": [1, 3, 7],
            "evaluator": [3, 10, 30]
        }
        this.userAwardLevels = ['BRONZE', 'SILVER', 'GOLD'];
    }

    /**
     * Get the users filtered.
     */
    @autobind
    public usersFiltered(): KnockoutComputed<UserDto[]> {
        return ko.pureComputed(() => {
            return this.users.filter(user => {
                if (!this.includeAdmins() && user.admin) {
                    return false;
                }
                return true;
            })
        });
    }

    /**
     * Get the user award level for the award type.
     * @param user
     * @param awardType
     * @return ['NONE|''BRONZE'|'SILVER|'GOLD']
     */
    public userAwardLevel(user: UserDto, awardType: string): string {
        const awardScore = this.userAwardScore(user, awardType);

        // The configured thresholds for this award.
        const threshold: Array<number> =
            (globalState.appVars.userAwardThresholds && globalState.appVars.userAwardThresholds[awardType])
                ? globalState.appVars.userAwardThresholds[awardType]
                : this.userAwardThresholdsDefault[awardType];

        let awardLevel = 'NONE';
        for (let i = (this.userAwardLevels.length - 1); i >= 0 && awardScore > 0; i--) {
            if (awardScore >= threshold[i]) {
                awardLevel = this.userAwardLevels[i];
                break
            }
        }
        return awardLevel;
    }

    public userAwardScore(user: UserDto, awardType: string): number {
        return user[awardType + 'Award'] ? user[awardType + 'Award'] : 0;
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./userAwards.html'),
    componentName: "userAwards",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report User Awards`;
        return Promise.all([
            userApi
                .getUsers(null, null, 'full')
                .then(users => ctx.users = users)
        ]);
    }
};

